@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hover-dshare-green {
    @apply hover:text-green hover:font-bold;
  }

  .hover-brand-green {
    @apply hover:bg-green hover:font-bold hover:text-white;
  }

  .indicator {
    @apply absolute bottom-0 rounded-[1px] h-[2px] z-10 bg-gray-400 duration-500 transition-all translate-x-0
  }
  .badge {
    @apply flex justify-center items-center py-[1.125rem] px-[1.875rem] rounded-xl mr-5 text-xl
  }
}